import React from "react"
import { Box, makeStyles, Typography } from "@material-ui/core"

import MainContent from "./main-content"
import CoButton from "./co-button"
import Image from "./image"

const useStyles = makeStyles(theme => ({
  locationImage: {
    width: "100%",
  },
  address: {
    fontWeight: "700",
  },
  address2: {
    marginBottom: theme.spacing(4),
  },
}))

const LocationSection = props => {
  const classes = useStyles()

  const mainContentStyles = {
    margin: "4rem auto",
  }

  return (
    <Box>
      <MainContent title="Clinic Address" buttonLabel="Navigate" mb="4rem">
        <Box textAlign="center">
          <Typography className={classes.address}>
            4300 North Fraser Way, Burnaby, BC
          </Typography>
          <Typography className={classes.address2}>
            Next Gen Offices, Glenlyon Corporate Centre
          </Typography>
          <CoButton type="primary" href="https://goo.gl/maps/wetKMvxq4HPs6Lv87">
            NAVIGATE
          </CoButton>
        </Box>
      </MainContent>
      <MainContent
        title="Parking and Entrance"
        mainContentStyles={mainContentStyles}
      >
        <Box textAlign="center">
          <Typography paragraph={true}>
            Park in the{" "}
            <Box component="span" fontWeight={700}>
              #100's
            </Box>{" "}
            stalls
            <br />
            DO NOT PARK in any other # stalls
          </Typography>
          <Typography>
            Please make sure you have ample time to travel so your treatment
            time will not be taken up by your travel time. To know where to
            enter please see diagram below.
          </Typography>
        </Box>
      </MainContent>
      <Box>
        <Image
          imgName="location.jpg"
          alt="Overhead map of Green Stone Wellness"
        />
      </Box>
    </Box>
  )
}

export default LocationSection
