import React from "react"

import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import AvatarDescription from "./avatar-description"

const useStyles = makeStyles(theme => ({
  profileSectionContainer: {
    marginBottom: props => props.mb,
  },
}))

const ProfileSection = props => {
  let { avatarDescription, children, title, description } = props
  const classes = useStyles(props)

  return (
    <Container className={classes.profileSectionContainer} maxWidth="sm">
      <AvatarDescription
        title={title}
        description={description}
        avatarDescription={avatarDescription}
      />
      {children}
    </Container>
  )
}

export default ProfileSection
