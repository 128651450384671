import React from "react"

import { Box, Typography } from "@material-ui/core"

import avatarMidori from "../images/midori.png"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  avatarDescriptionContainer: {
    "&:after": {
      content: "'.'",
      visibility: "hidden",
      height: "0",
      clear: "both",
    },
  },
  avatar: {
    float: "left",
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  description: {
    fontStyle: "italic",
  },
  heading: {
    margin: "0",
  },
}))

const AvatarDescription = props => {
  let { avatarDescription, title, description } = props
  const classes = useStyles()

  return (
    <Box className={classes.avatarDescriptionContainer} mb={1}>
      <img className={classes.avatar} src={avatarMidori} alt="Midori" />
      <Typography className={classes.heading} variant="h2">
        {title}
      </Typography>
      {description && (
        <Typography className={classes.description}>{description}</Typography>
      )}
      {avatarDescription}
    </Box>
  )
}

export default AvatarDescription
