import React, { useEffect } from "react"
import {
  ThemeProvider,
  CssBaseline,
  useTheme,
  makeStyles,
  Typography,
  Box,
  useMediaQuery,
} from "@material-ui/core"

import theme from "../theme"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProfileSection from "../components/profile-section"
import LocationSection from "../components/location-section"
import CoButton from "../components/co-button"
import credentials from "../images/credentials.png"
import ContactSection from "../components/contact-section"
import DialogClosedBooking from "../components/dialog-closed-booking"

const useStyles = makeStyles(theme => ({
  credentialsImg: {
    maxWidth: "100%",
    height: "auto",
  },
}))

const IndexPage = () => {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  const theme = useTheme()
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"))
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"))

  useEffect(() => {
    if (matchesMd) {
      setOpen(true)
    }
  }, [matchesMd])

  const data = {
    headline: "WHERE BALANCE MATTERS",
    headlineMaxWidth: "826px",
    tagline:
      "With a good therapist, having a massage session can bring balance back to your mind and body.",
    taglineMaxWidth: "454px",
    primaryBtnLabel: "BOOK ONLINE",
    primaryHref: "#",
    index: true,
    heroVh: "80vh",
    headerMb: "10rem",
  }

  const onOpenDialog = () => {
    setOpen(true)
  }

  const onCloseDialog = () => {
    setOpen(false)
  }

  return (
    <Layout data={data} onOpenDialog={onOpenDialog}>
      <SEO
        title="Home"
        description="With a good therapist, having a massage session can bring balance back to your mind and body."
      />
      <ProfileSection
        title="Midori Ishimaru"
        description="In Japanese, Midori Ishimaru translates to “Green (circular) Stone. When my massage techniques work for the patient’s needs they become my regulars and even book months in advance! Because…why not?"
        mb="10rem"
      >
        <Typography paragraph={true}>
          Midori Ishimaru is a Registered Massage Therapist (RMT) since 2017 and
          a Permanent Resident of British Columbia, Canada. She was born and
          raised in Japan, Ehime, Matsuyama City. She currently is a wife and a
          mother of two. She is also fluent in both the English and Japanese
          language.
        </Typography>
        <Box textAlign="center" mb={4}>
          <img
            className={classes.credentialsImg}
            src={credentials}
            alt="College of Massage Therapists of BC, Vancouver Career College, Registered Massage Therapist"
          />
        </Box>
        <Box textAlign="center">
          <CoButton type="secondary" href="/about">
            KNOW MORE
          </CoButton>
          {!matchesSm && (
            <CoButton type="primary" href="#" onClick={() => setOpen(true)}>
              BOOK ONLINE
            </CoButton>
          )}
        </Box>
      </ProfileSection>
      <ContactSection onOpenDialog={onOpenDialog} />
      <LocationSection />
      <DialogClosedBooking open={open} onCloseDialog={onCloseDialog} />
    </Layout>
  )
}

const StyledIndexPage = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <IndexPage />
  </ThemeProvider>
)

export default StyledIndexPage
